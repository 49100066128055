.glow, .card {
	position: relative;
}

.glow::before,
.glow::after {
	content: '';
	position: absolute;
	border-radius: 10px;
	left: -2px;
	top: -2px;
	background: linear-gradient(
		40deg,
		orange,
		yellow,
		orange,
		yellow,
		orange,
		yellow
	);
	background-size: 400%;
	width: calc(100% + 5px);
	height: calc(100% + 5px);
	z-index: -1;
	transition: opacity 0.5s ease;
	opacity: 0;
	filter: blur(40px);
}

.glow:hover::before,
.glow:hover::after {
	opacity: 0.5;
	animation: animateGlow 20s linear infinite;
}

.card::before,
.card::after {
	content: '';
	position: absolute;
	border-radius: 10px;
	left: -2px;
	top: -2px;
	width: calc(100% + 5px);
	height: calc(100% + 5px);
	transition: opacity 0.5s ease;
}

.card:hover::before,
.card:hover::after {
	box-shadow: 0 0 15px 2px rgb(0 0 0 / 20%);
	transition: box-shadow 0.2s ease;
}

@keyframes animateGlow {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}
